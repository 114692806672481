import React from "react";
import DefaultLayout from "../../components/DefaultLayout";
import Hero from "../../components/bricks/Hero";
import FeatureOverview from "../../components/FeatureOverview";
import ThemeBox from "../../components/bricks/ThemeBox";
import {themeBright, themeDark} from "../../page-styles/landingpage.css";
import ThemedSingleFeature from "../../components/bricks/ThemedSingleFeature";

const Features = ({location}) => (
  <DefaultLayout
    title="Open Decks - Show your Codecks project to the world!"
    description="Open Decks allow to share part of your project publicly with the your audience."
    location={location}
  >
    <Hero size="sm" title="Open Decks" subline="Show your Codecks project to the world!" />
    <ThemeBox theme={themeBright} withBorder>
      <ThemedSingleFeature
        title="We love open dev"
        childAsDescription
        img="next/open-development.png"
      >
        We’re big supporters of open development and are building Codecks to be a great platform for
        it. Our Open Decks make it possible to share the contents of any of your Codecks projects
        publicly on the web. Anyone is able to navigate to your page and see all the project cards
        and their contents.
      </ThemedSingleFeature>

      <ThemedSingleFeature
        title="Joining matters"
        childAsDescription
        img="open-development/joining-matters.png"
        imgRight
      >
        Fans visiting your Open Decks can join your community, allowing them to upvote individual
        cards and receive email updates about your project that are automatically created.
      </ThemedSingleFeature>
    </ThemeBox>

    <ThemeBox theme={themeDark} withBorder title={null}>
      <ThemedSingleFeature title="On show" childAsDescription img="open-development/on-show.png">
        The Open Decks Gallery is a great way for discovering cool projects that are already using
        the Open Decks feature and to get inspired by how other devs are using Codecks.
      </ThemedSingleFeature>

      <ThemedSingleFeature
        title="Every vote counts"
        childAsDescription
        img="open-development/every-vote-counts.png"
        imgRight
      >
        Your own team members can also join in on the fun and upvote cards inside your project: a
        potential use case is to signal group agreement regarding game design ideas.
      </ThemedSingleFeature>

      <ThemedSingleFeature
        title="All the numbers"
        childAsDescription
        img="open-development/all-the-numbers.png"
      >
        Stats give an overview of the latest upvotes, including those from your Open Decks, your
        Discord community and your own team as well. They also display how many guests are visiting
        your public Open Decks page.
      </ThemedSingleFeature>
    </ThemeBox>
    <FeatureOverview />
  </DefaultLayout>
);

export default Features;
